import styled from 'styled-components'
import { Box } from './components/box'
import { BigHeading } from './components/big-heading'
import React, { ReactElement } from 'react'
import { sizes } from '../../styles/sizes'

const CenteringWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 17rem;
`
const SpacingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    padding: 1rem;
  }
`
const BoxWrapper = styled.div`
  display: flex;
  gap: 3rem;

  @media (max-width: ${sizes.breakpointsMax.lg}) {
    flex-direction: column;
  }
`
export const SectionBoxes = ({
  headings,
  sectionHeading,
  texts,
}: {
  headings: string[]
  sectionHeading: string
  texts: ReactElement[]
}) => (
  <CenteringWrapper>
    <SpacingWrapper>
      <BigHeading big={true} gradient={false} text={sectionHeading} />
      <BoxWrapper>
        {headings.map((heading, index) => (
          <Box heading={heading} text={texts[index]} />
        ))}
      </BoxWrapper>
    </SpacingWrapper>
  </CenteringWrapper>
)
