import styled from 'styled-components'
import { BigHeading } from './components/big-heading'
import { SectionWithoutImage } from './section-without-image'
import React, { ReactElement } from 'react'
import colors from '../../../../client/src/styles/colors'
import { sizes } from '../../styles/sizes'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  padding-top: 8rem;
  gap: 4rem;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    margin-top: 8rem;
  }
`

export const SectionGrey = ({
  big,
  gradient,
  bigHeadingText,
  heading1,
  heading2,
  text1,
  text2,
}: {
  big: boolean
  gradient: boolean
  bigHeadingText: string
  heading1: string
  heading2: string
  text1: ReactElement
  text2: ReactElement
}) => (
  <Wrapper>
    <SectionWithoutImage
      heading1={heading1}
      heading2={heading2}
      text1={text1}
      text2={text2}
      title={<BigHeading big={big} gradient={gradient} text={bigHeadingText} />}
    />
  </Wrapper>
)
