import React, { ReactNode, CSSProperties } from 'react'
import styled from 'styled-components'
import { fontStyles } from '../../../styles/variables'

interface IProps {
  children: ReactNode
  style?: CSSProperties | undefined
  color?: string
}

const ListStyled = styled.ul<{ $color: string | undefined }>`
  max-width: 480px;
  padding-top: 24px;
  padding-left: 30px;
  ${fontStyles.textList}
  list-style-type: none;

  li {
    padding-right: 1.5em;
    padding-bottom: 1em;
  }

  li::before {
    content: '•';
    color: ${p => (p.$color ? p.$color : 'black')};
    width: 6em;
    font-size: 1.5rem;
    margin-left: -1.5em;
    padding-right: 1em;
    font-weight: bold;
  }
`

const List = (props: IProps) => {
  return (
    <ListStyled $color={props.color} style={props.style}>
      {props.children}
    </ListStyled>
  )
}

export default List
