import React from 'react'
import Layout from '../components/layout/layout'
import Footer from '../components/footer'
import TextBold from '../components/sections/components/text-bold'
import Text from '../components/sections/components/text'
import { injectIntl, IntlFormatters } from 'gatsby-plugin-intl'
import { bazar } from '../components/images/bazar'
import { products } from '../components/images/products'
import { library } from '../components/images/library'
import { SectionOneIndex } from '../components/sections/section-one-index'
import { SectionTwoIndex } from '../components/sections/section-two-index'
import { news } from '../components/images/news'
import colors from '../../../client/src/styles/colors'
import { SectionSmall } from '../components/sections/section-small'
import { SectionWithoutImage } from '../components/sections/section-without-image'
import { SectionGrey } from '../components/sections/section-grey'
import { SectionFuturePlans } from '../components/sections/section-future-plans'
import Ai from '../images/ai.svg'
import Community from '../images/community.svg'
import Global from '../images/global.svg'
import Appearance from '../images/appearance.svg'
import OtherFields from '../images/other_fields.svg'
import Gcms from '../images/LabRulezGCMS_logo.svg'
import Lcms from '../images/LabRulezLCMS_logo.svg'
import Icpms from '../images/LabRulezICPMS_logo.svg'
import { SectionBoxes } from '../components/sections/section-boxes'
import { SectionWhereNext } from '../components/sections/section-where-next'
import styled from 'styled-components'
import { sizes } from '../styles/sizes'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 280px;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    gap: 11rem;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    gap: 4rem;
  }
`

const IndexPage = ({ intl }: IntlFormatters) => {
  const bazarImage = bazar()
  const productsImage = products()
  const libraryImage = library()
  const newsImage = news()
  return (
    <Layout>
      <Wrapper>
        <SectionOneIndex
          heading={intl.formatMessage({ id: 'indexPage.sectionOne.heading' })}
          text={
            <>
              <Text>
                {intl.formatMessage({ id: 'indexPage.sectionOne.text1' })}
                <TextBold>{intl.formatMessage({ id: 'indexPage.sectionOne.text2' })}</TextBold>
              </Text>
            </>
          }
          headingLink={intl.formatMessage({ id: 'indexPage.sectionOne.subheading' })}
          icons={[<Gcms />, <Lcms />, <Icpms />]}
          links={[
            intl.formatMessage({ id: 'link.gcms' }),
            intl.formatMessage({ id: 'link.lcms' }),
            intl.formatMessage({ id: 'link.icpms' }),
          ]}
          big={true}
        />
        {/* news*/}
        <SectionTwoIndex
          subheading={intl.formatMessage({ id: 'indexPage.sectionTwo.subheading' })}
          heading1={intl.formatMessage({ id: 'indexPage.sectionTwo.heading' })}
          list={
            <span>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionTwo.text1' })}</Text>
              </li>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionTwo.text2' })}</Text>
              </li>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionTwo.text3' })}</Text>
              </li>
            </span>
          }
          image={newsImage}
          color={colors.news}
          heading2={intl.formatMessage({ id: 'indexPage.sectionThree.heading1' })}
          text1={<Text>{intl.formatMessage({ id: 'indexPage.sectionThree.text1' })}</Text>}
          heading3={intl.formatMessage({ id: 'indexPage.sectionThree.heading2' })}
          text2={<Text>{intl.formatMessage({ id: 'indexPage.sectionThree.text2' })}</Text>}
          left={true}
        />
        {/* library*/}
        <SectionSmall
          color={colors.library}
          heading={intl.formatMessage({ id: 'indexPage.sectionFour.heading' })}
          image={libraryImage}
          subheading={intl.formatMessage({ id: 'indexPage.sectionFour.subheading' })}
          list={
            <span>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionFour.text1' })}</Text>
              </li>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionFour.text2' })}</Text>
              </li>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionFour.text3' })}</Text>
              </li>
            </span>
          }
          left={false}
        />
        {/* products*/}
        <SectionSmall
          color={colors.products}
          heading={intl.formatMessage({ id: 'indexPage.sectionFive.heading' })}
          image={productsImage}
          subheading={intl.formatMessage({ id: 'indexPage.sectionFive.subheading' })}
          left={true}
          list={
            <span>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionFive.text1' })}</Text>
              </li>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionFive.text2' })}</Text>
              </li>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionFive.text3' })}</Text>
              </li>
            </span>
          }
        />
        {/* bazar*/}
        <SectionSmall
          color={colors.products}
          heading={intl.formatMessage({ id: 'indexPage.sectionSix.heading' })}
          image={bazarImage}
          subheading={intl.formatMessage({ id: 'indexPage.sectionSix.subheading' })}
          left={false}
          list={
            <span>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionSix.text1' })}</Text>
              </li>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionSix.text2' })}</Text>
              </li>
              <li>
                <Text>{intl.formatMessage({ id: 'indexPage.sectionSix.text3' })}</Text>
              </li>
            </span>
          }
        />
        {/* Company and career*/}
        <SectionWithoutImage
          heading1={intl.formatMessage({ id: 'indexPage.sectionSeven.heading' })}
          subheading1={intl.formatMessage({ id: 'indexPage.sectionSeven.subheading' })}
          text1={<Text>{intl.formatMessage({ id: 'indexPage.sectionSeven.text' })}</Text>}
          color={colors.companies}
          heading2={intl.formatMessage({ id: 'indexPage.sectionEight.heading' })}
          subheading2={intl.formatMessage({ id: 'indexPage.sectionEight.subheading' })}
          text2={<Text>{intl.formatMessage({ id: 'indexPage.sectionEight.text' })}</Text>}
          color2={colors.career}
        />
      </Wrapper>
      {/*future development*/}
      <SectionGrey
        big={true}
        bigHeadingText={intl.formatMessage({ id: 'indexPage.sectionNine.heading1' })}
        gradient={false}
        heading1={intl.formatMessage({ id: 'indexPage.sectionNine.heading2' })}
        text1={<Text>{intl.formatMessage({ id: 'indexPage.sectionNine.text1' })}</Text>}
        heading2={intl.formatMessage({ id: 'indexPage.sectionNine.heading3' })}
        text2={<Text>{intl.formatMessage({ id: 'indexPage.sectionNine.text2' })}</Text>}
      />
      <SectionFuturePlans
        heading={intl.formatMessage({ id: 'indexPage.sectionTen.heading' })}
        icons={[<OtherFields />, <Global />, <Community />, <Ai />]}
        texts={[
          <>
            <Text>
              {intl.formatMessage({ id: 'indexPage.sectionTen.text1' })}
              <TextBold>{intl.formatMessage({ id: 'indexPage.sectionTen.text2' })}</TextBold>
            </Text>
          </>,
          <>
            <Text>
              {intl.formatMessage({ id: 'indexPage.sectionTen.text3' })}
              <TextBold>{intl.formatMessage({ id: 'indexPage.sectionTen.text4' })}</TextBold>
              {intl.formatMessage({ id: 'indexPage.sectionTen.text5' })}
            </Text>
          </>,
          <>
            <Text>
              {intl.formatMessage({ id: 'indexPage.sectionTen.text6' })}
              <TextBold>{intl.formatMessage({ id: 'indexPage.sectionTen.text7' })}</TextBold>
            </Text>
          </>,
          <>
            <Text>
              {intl.formatMessage({ id: 'indexPage.sectionTen.text8' })}
              <TextBold>{intl.formatMessage({ id: 'indexPage.sectionTen.text9' })}</TextBold>
              {intl.formatMessage({ id: 'indexPage.sectionTen.text10' })}
            </Text>
          </>,
        ]}
      />
      <SectionBoxes
        sectionHeading={intl.formatMessage({ id: 'indexPage.sectionEleven.heading1' })}
        headings={[
          intl.formatMessage({ id: 'indexPage.sectionEleven.heading2' }),
          intl.formatMessage({ id: 'indexPage.sectionEleven.heading3' }),
        ]}
        texts={[
          <Text>{intl.formatMessage({ id: 'indexPage.sectionEleven.text1' })}</Text>,
          <Text>{intl.formatMessage({ id: 'indexPage.sectionEleven.text2' })}</Text>,
        ]}
      />
      <SectionWhereNext
        heading={intl.formatMessage({ id: 'indexPage.sectionTwelve.heading' })}
        Image={Appearance}
        links={[intl.formatMessage({ id: 'link.forUser' }), intl.formatMessage({ id: 'link.forCompany' })]}
        texts={[
          <TextBold>{intl.formatMessage({ id: 'indexPage.sectionTwelve.text1' })}</TextBold>,
          <TextBold>{intl.formatMessage({ id: 'indexPage.sectionTwelve.text2' })}</TextBold>,
        ]}
      />
      <Footer />
    </Layout>
  )
}

export default injectIntl(IndexPage)
