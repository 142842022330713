import styled from 'styled-components'
import React, { ReactElement } from 'react'
import { fontStyles } from '../../../styles/variables'
import BoxArrow from '../../../images/box-arrow.svg'
import { sizes } from '../../../styles/sizes'

const Heading = styled.h1`
  ${fontStyles.subheading}
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: #00000029 6px 6px 24px;
  width: 645px;
  @media (max-width: ${sizes.breakpointsMax.hg}) {
    width: 475px;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    width: 520px;
    height: 267px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 100%;
  }
`
const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 4rem;
  padding-right: 2rem;
  height: 79px;
  align-items: center;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    padding: 0 1rem;
    height: 3rem;
  }
`
const Grandient = styled.div`
  width: 100%;
  min-height: 8px;
  background: transparent
    linear-gradient(90deg, #ffc107 0%, #f27405 22%, #d92b04 42%, #be0f8f 66%, #2975d9 88%, #2fc3d4 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;
`
const Text = styled.span`
  padding: 1.5rem 4rem 2.5rem 4rem;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    padding: 2.5rem 4rem 2.5rem 4rem;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    padding: 1rem;
  }
`

export const Box = ({ heading, text }: { heading: string; text: ReactElement }) => (
  <Container>
    <HeadingContainer>
      <Heading>{heading}</Heading>
      <BoxArrow />
    </HeadingContainer>
    <Grandient />
    <Text>{text}</Text>
  </Container>
)
