import React, { ReactElement } from 'react'
import { SectionIcons } from './section-icons'
import styled from 'styled-components'
import { BigHeading } from './components/big-heading'
import { sizes } from '../../styles/sizes'

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
  @media (max-width: ${sizes.breakpointsMax.hg}) {
    padding-left: 2rem;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    padding: 0 1rem;
    padding-top: 4rem;
  }
`
const SpacingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`
export const SectionFuturePlans = ({
  heading,
  texts,
  icons,
}: {
  heading: string
  texts: ReactElement[]
  icons: ReactElement[]
}) => (
  <CenteringWrapper>
    <SpacingWrapper>
      <BigHeading big={false} gradient={true} text={heading} />
      <SectionIcons svgImages={icons} texts={texts} />
    </SpacingWrapper>
  </CenteringWrapper>
)
