import React, { ReactNode, CSSProperties, ReactElement } from 'react'
import styled from 'styled-components'
import BoxArrow from '../../../images/box-arrow.svg'
import { Link } from 'gatsby'
import { fontStyles } from '../../../styles/variables'
import { ButtonLinkStyled } from './button-href'
import { sizes } from '../../../styles/sizes'
interface IProps {
  children?: ReactNode
  style?: CSSProperties | undefined
  text: string
  to: string
}

const IconLinkContainer = styled.div`
  display: flex;
  width: 29.5rem;
  height: 4rem;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid black;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 20px;
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    padding-right: 1rem;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    max-width: 100%;
    height: 56px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    max-width: 274px;
    height: 56px;
  }
`
const IconLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    gap: 1rem;
  }
`
const IconLinkHeading = styled.h2`
  ${fontStyles.iconLinkHeading};
`

export const LinksWithIcons = ({
  heading,
  links,
  icons,
}: {
  heading: string
  links: string[]
  icons: ReactElement[]
}) => (
  <IconLinksWrapper>
    <IconLinkHeading>{heading}</IconLinkHeading>
    {links.map((link, index) => (
      <a href={link}>
        <IconLinkContainer>
          <div>{icons[index]}</div>
          <BoxArrow />
        </IconLinkContainer>
      </a>
    ))}
  </IconLinksWrapper>
)

const ButtonLink = (props: IProps) => {
  return (
    <Link to={props.to}>
      <ButtonLinkStyled style={props.style}>
        <div className="box-button">
          <div className="box-text">{props.text}</div>
          <div className="box-arrow">
            <BoxArrow />
          </div>
        </div>
      </ButtonLinkStyled>
    </Link>
  )
}

export default ButtonLink
