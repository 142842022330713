import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import BoxArrow from '../../../images/box-arrow.svg'
import { fontStyles } from '../../../styles/variables'

interface IProps {
  style?: CSSProperties | undefined
  text: string
  to: string
}

export const ButtonLinkStyled = styled.div`
  position: absolute;
  width: 100%;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid black;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  & .box-text {
    position: relative;
    left: 20px;
    height: 25px;
    text-align: left;
    ${fontStyles.contactbutton}
    letter-spacing: 0;
    color: #000000;
  }

  & .box-arrow {
    position: absolute;
    float: right;
    width: 30px;
    height: 30px;
    top: 5px;
    right: 20px;
  }
`

const ButtonHref = (props: IProps) => {
  return (
    <a href={props.to} target="_blank" rel="noopener noreferrer">
      <ButtonLinkStyled style={props.style}>
        <div className="box-button">
          <div className="box-text">{props.text}</div>
          <div className="box-arrow">
            <BoxArrow />
          </div>
        </div>
      </ButtonLinkStyled>
    </a>
  )
}

export default ButtonHref
