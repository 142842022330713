import React, { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'
import { sizes } from '../../styles/sizes'
import Container from './components/container'

const CenteringWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16rem;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    padding-bottom: 0;
    justify-content: center;
  }
`

const SpacingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    gap: 0;
  }
`

const Row = styled.div`
  display: flex;
  white-space: pre-line;
  gap: 20.5rem;

  @media (max-width: ${sizes.breakpointsMax.hg}) {
    gap: 15rem;
  }
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    gap: 6rem;
  }
  /* tablet */
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    gap: 8.5rem;
    padding-top: 50px;
    padding-bottom: 10.5rem;
    flex-direction: column;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    gap: 4rem;
    flex-direction: column;
  }
`

interface NoImage {
  heading1: string
  text1: ReactElement
  heading2: string
  text2: ReactElement
  color?: string
  subheading1?: string
  subheading2?: string
  color2?: string
  title?: ReactNode
}

export const SectionWithoutImage = (props: NoImage) => {
  return (
    <CenteringWrapper>
      <SpacingWrapper>
        {props.title}
        <Row>
          <Container heading={props.heading1} text={props.text1} color={props.color} subheading={props.subheading1} />
          <Container
            heading={props.heading2}
            text={props.text2}
            color={props.color2 ?? props.color}
            subheading={props.subheading2}
          />
        </Row>
      </SpacingWrapper>
    </CenteringWrapper>
  )
}
