import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { sizes } from '../../styles/sizes'
const Container = styled.div`
  display: flex;
  height: 20.25rem;
  width: 18.75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: #00000029 6px 6px 24px;
  gap: 1.5rem;
  padding: 0 2rem;
`

const IconsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 44px;
  justify-content: center;
  @media (max-width: ${sizes.breakpointsMax.hg}) {
    gap: 1rem;
  }
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 0;
    row-gap: 2.5rem;
    justify-items: center;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 1.5rem;
    justify-items: center;
  }
`
const TextWrapper = styled.span`
  text-align: center;
`
export const SectionIcons = ({ svgImages, texts }: { svgImages: ReactElement[]; texts: ReactElement[] }) => {
  return (
    <IconsWrapper>
      {texts.map((text, index) => (
        <Container>
          <div>{svgImages[index]}</div>
          <TextWrapper>{text}</TextWrapper>
        </Container>
      ))}
    </IconsWrapper>
  )
}
