import { LinksWithIcons } from './components/button-link'
import React, { ReactElement } from 'react'
import Container from './components/container'
import styled from 'styled-components'
import { sizes } from '../../styles/sizes'
import BackgroundVideo from '../../video/background-video.mp4'
import Wave from '../../images/wave.svg'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20.5rem;
  @media (max-width: ${sizes.breakpointsMax.hg}) {
    padding: 0 1rem;
  }
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    gap: 9rem;
  }
  //tablet
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    padding-top: 5rem;
    flex-direction: column;
    align-items: center;
    gap: 7rem;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    gap: 2.5rem;
  }
`
const Video = styled.video`
  width: 100%;
  aspect-ratio: 3/1;
  object-fit: cover;
  object-position: bottom;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    display: none;
  }
`
const WaveAnchor = styled.div`
  position: relative;
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    display: none;
  }
`
const WavePositionContainer = styled.div`
  width: 100%;
  position: absolute;

  /* what? 😭 (franta) */
  /*
   * zero is the obviously correct value,
   * but the perils of web force us to do this or there is a
   * random single-pixel high gap between the wave and the video.
   */
  bottom: -1px;
`
const SWave = styled(Wave)`
  width: 100%;
  height: auto;
`

export const SectionOneIndex = ({
  heading,
  text,
  headingLink,
  icons,
  links,
  big,
}: {
  heading: string
  text: ReactElement
  headingLink: string
  icons: ReactElement[]
  links: string[]
  big?: boolean
}) => {
  return (
    <div>
      <WaveAnchor>
        <Video loop muted autoPlay playsInline>
          <source src={BackgroundVideo} type="video/mp4" />
        </Video>
        <WavePositionContainer>
          <SWave />
        </WavePositionContainer>
      </WaveAnchor>
      <Wrapper>
        <Container heading={heading} text={text} big={big} />
        <LinksWithIcons heading={headingLink} icons={icons} links={links} />
      </Wrapper>
    </div>
  )
}
