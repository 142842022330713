import styled from 'styled-components'
import Container from './components/container-list'
import React, { ReactElement } from 'react'
import { FixedObject } from 'gatsby-image'
import Img from 'gatsby-image'
import { sizes } from '../../styles/sizes'

const SectionWrapper = styled.div`
  display: flex;
  height: 650px;
  width: 100%;
  gap: 214px;
  justify-content: center;
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    gap: 2rem;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    flex-direction: column;
    align-items: center;
    gap: 7rem;
    height: auto;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`
const RightWrapper = styled.div`
  display: flex;
  padding-left: 234px;
  gap: 214px;
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    gap: 2rem;
    padding-left: 1rem;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    flex-direction: column-reverse;
    padding-left: 0px;
    gap: 7rem;
    align-items: center;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    flex-direction: column-reverse;
    align-items: center;
    padding-left: 0px;
    gap: 2rem;
  }
`

export const SectionSmall = ({
  image,
  heading,
  list,
  color,
  subheading,
  left,
}: {
  image: FixedObject
  heading: string
  list: ReactElement
  color: string
  subheading: string
  left: boolean
}) => (
  <SectionWrapper>
    {left ? (
      <>
        <Img fixed={image} />
        <Container heading={heading} children={list} color={color} subheading={subheading} />
      </>
    ) : (
      <RightWrapper>
        <Container heading={heading} children={list} color={color} subheading={subheading} />
        <Img fixed={image} />
      </RightWrapper>
    )}
  </SectionWrapper>
)
