import React, { ComponentType, ReactElement } from 'react'
import styled from 'styled-components'
import { fontStyles } from '../../styles/variables'
import GrandientLine from './components/grandient-line'
import BoxArrow from '../../images/box-arrow.svg'
import { sizes } from '../../styles/sizes'
const Wrapper = styled.div`
  display: flex;
  gap: 10rem;
  justify-content: center;
  align-items: flex-start;
  padding-top: 9rem;
  padding-bottom: 4rem;
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    gap: 0;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    flex-direction: column;
    align-items: center;
    gap: 0;
    padding-bottom: 8.5rem;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    flex-direction: column;
    align-items: center;
    gap: 0;
    padding-bottom: 4rem;
    padding-top: 0rem;
    width: 100%;
  }
`
const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 472px;
  gap: 2rem;
  padding-right: 1rem;
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 100%;
    padding: 0 1rem;
  }
`
const Heading = styled.h1`
  ${fontStyles.heading}
  width:100%;
`
const LinkContainer = styled.div`
  display: flex;
  width: 29.5rem;
  height: 4rem;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid black;
  align-items: center;
  justify-content: space-between;
  padding-left: 2rem;
  padding-right: 20px;
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    width: 424px;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 100%;
  }
`
const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media (max-width: ${sizes.breakpointsMax.sm}) {
    width: 100%;
  }
`
const ImageWrapper = styled.div`
  .image {
    @media (max-width: ${sizes.breakpointsMax.hg}) {
      width: 500px;
      height: 500px;
    }
    @media (max-width: ${sizes.breakpointsMax.lg}) {
      width: 500px;
      height: 500px;
    }
    @media (max-width: ${sizes.breakpointsMax.sm}) {
      width: 274px;
      height: 274px;
    }
  }
`
export const SectionWhereNext = ({
  Image,
  heading,
  links,
  texts,
}: {
  Image: ComponentType<{ className: string }>
  heading: string
  links: string[]
  texts: ReactElement[]
}) => (
  <Wrapper>
    <ImageWrapper>
      <Image className="image" />
    </ImageWrapper>
    <Right>
      <Heading>{heading}</Heading>
      <GrandientLine />
      <LinksWrapper>
        {links.map((link, index) => (
          <a href={link}>
            <LinkContainer>
              {texts[index]}
              <BoxArrow />
            </LinkContainer>
          </a>
        ))}
      </LinksWrapper>
    </Right>
  </Wrapper>
)
