import styled from 'styled-components'
import { SectionSmall } from './section-small'
import React, { ReactElement } from 'react'
import Container from './components/container'
import { FixedObject } from 'gatsby-image'
import { sizes } from '../../styles/sizes'
const Wrapper = styled.div`
  height: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 12rem;
  gap: 10rem;

  @media (max-width: ${sizes.breakpointsMax.lg}) {
    height: auto;
    gap: 8.5rem;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    gap: 4rem;
    padding-bottom: 0;
  }
`
const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 118px;
  gap: 324px;
  @media (max-width: ${sizes.breakpointsMax.xl}) {
    padding-left: 0;
    gap: 6rem;
  }
  @media (max-width: ${sizes.breakpointsMax.lg}) {
    flex-direction: column;
    padding-left: 0;
    align-items: center;
    gap: 8.5rem;
  }
  @media (max-width: ${sizes.breakpointsMax.sm}) {
    gap: 4rem;
  }
`
export const SectionTwoIndex = ({
  list,
  subheading,
  heading1,
  image,
  heading2,
  text1,
  heading3,
  text2,
  color,
  left,
}: {
  list: ReactElement
  subheading: string
  heading1: string
  image: FixedObject
  heading2: string
  text1: ReactElement
  heading3: string
  text2: ReactElement
  color: string
  left: boolean
}) => {
  return (
    <Wrapper>
      <SectionSmall left={left} list={list} subheading={subheading} heading={heading1} image={image} color={color} />
      <TextWrapper>
        <Container heading={heading2} text={text1} color={color} />
        <Container heading={heading3} text={text2} color={color} />
      </TextWrapper>
    </Wrapper>
  )
}
