import React, { ReactNode, CSSProperties } from 'react'
import GrandientLine from './grandient-line'
import Heading from './heading'
import List from './list'
import { ColorLine, ContainerStyled, Subheading, SubheadingColored } from './container'

interface ContainerIProps {
  style?: CSSProperties | undefined
  heading: string
  children: ReactNode
  color?: string
  subheading?: string
}

const Container = (props: ContainerIProps) => {
  return (
    <ContainerStyled {...props}>
      {props.subheading && props.color ? (
        <SubheadingColored $color={props.color}>{props.subheading}</SubheadingColored>
      ) : (
        <Subheading>{props.subheading}</Subheading>
      )}
      <Heading>{props.heading}</Heading>
      {props.color ? <ColorLine $color={props.color} /> : <GrandientLine />}
      <List color={props.color}>{props.children}</List>
    </ContainerStyled>
  )
}

export default Container
